<template>
      <div class="container">
		<div class="row">
			<div class="col-12">
				<div class="sign__content">
               <form class="sign__form" @submit.prevent="Subscrib()">
                  <router-link to="/" class="sign__logo">
                     <img src="static/img/logoSvg.svg" alt="">
                  </router-link>
						<div class="sign__group">
                     <p class="" style="font-size: 21px; text-align: center; margin-bottom: 10px; font-family:'Zain' ,sans-serif;width: 100%;">إضبط وزنك</p>
                     <p style="text-align:justify;font-family:'Zain' ,sans-serif;font-weight:600;">
                        عبارة عن مجموعة من النصائح مقدمة من د. هبة المعتمد تهدف لضبط وزنك وتعديل سلوكك الغذائي تشمل كمية الطعام وكيفية تحضيره مع تحديد الزمن المناسب للوجبات وغيرها من الإرشادات التي تساعدك في كسب نمط حياة صحي.
                     </p>
                     <!-- <input type="phone" v-model="msisdn" class="sign__input input" autocomplete="off" placeholder="رقم الهاتف" onfocus="this.placeholder = ''" onblur="this.placeholder = '09xxxxxxxx'"> -->
						</div>
                  <div class="sign__group">
                     <!-- <button class="sign__btn" type="submit">تسجيل دخول</button> -->
                     <button class="sign__btn button" style="color:#fff" >اشــتراك
                        <!-- <span v-if="loading" class="px-1 ">جاري اشــتراك</span> &nbsp;
                        <div v-if="loading" class="spinner-border spinner-border-sm"></div>
                        <span v-else class="arabickufi">اشــتراك</span> -->
                     </button>
                     <span class="sign__delimiter"><h6></h6></span>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
  name: 'AppSubscribes',
    mounted() {
        if (!this.$cookie.isCookieAvailable("msisdn") && !this.$cookie.isCookieAvailable("status")) {
            return 
        } else {
            return this.$router.go(-1)
        }
    },
    setup() {
        const Subscrib = () => {
            window.open("https://dsplp.sd.zain.com/?p=4372482367", "_self");
        }
        return { Subscrib }
    }
}
</script>

<style>


</style>